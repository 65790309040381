import './hamburger';

const setupEnterNavigation = () => {
  const menuDivs = document.querySelectorAll<HTMLElement>('.app-header__bar__link');

  menuDivs.forEach((menuDiv) => {
    const anchorLink = menuDiv.querySelector<HTMLAnchorElement>('a');

    menuDiv.addEventListener('keydown', (event) => {
      if (event.key === 'Enter' && anchorLink) {
        anchorLink.click();
      }
    });
  });
};

document.addEventListener('DOMContentLoaded', () => {
  setupEnterNavigation();

  const menuDesktop = document.getElementById('menu-desktop') as HTMLElement;
  const menuContents = Array.from(document.getElementsByClassName('menu-desktop__content')) as HTMLElement[];
  const menuLinks = Array.from(document.querySelectorAll('.app-header__bar__link')) as HTMLElement[];

  let activeIdx = 0;

  const hideAllMenuContents = () => {
    menuContents.forEach((content) => content.classList.add('hidden'));
  };

  const hideMenuDesktop = () => {
    menuDesktop.classList.add('hidden');
    hideAllMenuContents();
  };

  const toggleMenuContent = (link: HTMLElement, focusFirstItem = false) => {
    const id = link.id.replace('menu-link-', '');
    const content = document.getElementById(`menu-content-${id}`) as HTMLElement | null;
    activeIdx = menuLinks.indexOf(link);

    if (content) {
      hideAllMenuContents();
      content.classList.remove('hidden');
      menuDesktop.classList.remove('hidden');
      if (focusFirstItem) {
        setTimeout(() => {
          const firstLink = content.querySelector('a');
          firstLink?.focus();
        }, 0);
      }
    } else {
      hideMenuDesktop();
    }
  };

  const handleMainMenuKeydown = (event: KeyboardEvent) => {
    const index = menuLinks.indexOf(event.target as HTMLElement);
    switch (event.key) {
      case 'ArrowRight':
        event.preventDefault();
        const nextIndex = (index + 1) % menuLinks.length;
        menuLinks[nextIndex].focus();
        break;
      case 'ArrowLeft':
        event.preventDefault();
        const prevIndex = (index - 1 + menuLinks.length) % menuLinks.length;
        menuLinks[prevIndex].focus();
        break;
      case 'ArrowDown':
        event.preventDefault();
        toggleMenuContent(event.target as HTMLElement, true);
        break;
    }
  };

  const isLastLinkInList = (link: HTMLAnchorElement, list: HTMLElement) => {
    const lastLink = list.querySelector('li:last-child a') as HTMLAnchorElement;
    return link === lastLink;
  };

  const findNextList = (currentList: HTMLElement): HTMLElement | null => {
    let nextContainer = currentList.parentElement?.nextElementSibling as HTMLElement | null;
    while (nextContainer) {
      const ul = nextContainer.querySelector('ul') as HTMLElement | null;
      if (ul) {
        return ul;
      }
      nextContainer = nextContainer.nextElementSibling as HTMLElement | null;
    }
    return null;
  };

  const focusNextMainMenuItem = (currentLink: HTMLElement) => {
    const currentIndex = menuLinks.indexOf(currentLink.closest('.app-header__bar__link') as HTMLElement);
    const nextIndex = (currentIndex + 1) % menuLinks.length;
    const nextMainLink = menuLinks[nextIndex];
    nextMainLink.focus();

    activeIdx = nextIndex;
  };

  const handleSubMenuNavigation = (event: KeyboardEvent, activeElement: HTMLElement) => {
    const currentList = activeElement.closest('ul') as HTMLElement | null;
    let targetElement: HTMLAnchorElement | undefined;

    if (event.key === 'ArrowDown') {
      targetElement =
        (activeElement.parentElement?.nextElementSibling?.querySelector('a') as HTMLAnchorElement | undefined) ||
        (activeElement.closest('.item')?.nextElementSibling?.querySelector('a') as HTMLAnchorElement | undefined);
    } else if (event.key === 'ArrowUp') {
      targetElement =
        (activeElement.parentElement?.previousElementSibling?.querySelector('a') as HTMLAnchorElement | undefined) ||
        (activeElement.closest('.item')?.previousElementSibling?.lastElementChild?.querySelector('a') as
          | HTMLAnchorElement
          | undefined);
    }

    if (
      !targetElement &&
      event.key === 'ArrowDown' &&
      activeElement === currentList?.lastElementChild?.querySelector('a')
    ) {
      event.preventDefault();
      const activeMenu = menuLinks[activeIdx];
      focusNextMainMenuItem(activeMenu);
      return;
    }

    if (targetElement) {
      event.preventDefault();
      targetElement.focus();
    } else {
      if (event.key === 'ArrowUp' && !activeElement.parentElement?.previousElementSibling) {
        const lastList = activeElement.closest('.menu-desktop__content')?.querySelectorAll('.item ul');
        lastList && lastList[lastList.length - 1]?.lastElementChild?.querySelector('a')?.focus();
        event.preventDefault();

        const activeMenu = menuLinks[activeIdx];
        activeMenu?.focus();
      } else if (event.key === 'ArrowDown' && !activeElement.parentElement?.nextElementSibling) {
        const firstList = activeElement.closest('.menu-desktop__content')?.querySelector('.item ul');
        firstList?.firstElementChild?.querySelector('a')?.focus();
        event.preventDefault();
      }
    }
  };

  menuLinks.forEach((link) => {
    link.addEventListener('mouseover', () => toggleMenuContent(link));
    link.addEventListener('focus', () => toggleMenuContent(link));
    link.addEventListener('keydown', handleMainMenuKeydown);
  });

  menuDesktop.addEventListener('mouseleave', hideMenuDesktop);

  document.addEventListener('keydown', (event: KeyboardEvent) => {
    const activeElement = document.activeElement as HTMLElement;
    if (
      ['ArrowDown', 'ArrowUp'].includes(event.key) &&
      activeElement.tagName === 'A' &&
      activeElement.closest('.menu-desktop__content')
    ) {
      handleSubMenuNavigation(event, activeElement);
    }
  });

  document.addEventListener('keydown', (event: KeyboardEvent) => {
    if (event.key === 'Tab' && !event.shiftKey) {
      const activeElement = document.activeElement as HTMLAnchorElement;
      const currentList = activeElement.closest('ul') as HTMLElement | null;
      if (currentList && isLastLinkInList(activeElement, currentList)) {
        event.preventDefault();
        const nextList = findNextList(currentList);
        if (nextList) {
          const firstLink = nextList.querySelector('a') as HTMLAnchorElement | null;
          firstLink?.focus();
        } else {
          focusNextMainMenuItem(activeElement);
        }
      }
    }
  });
});
