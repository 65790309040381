document.addEventListener('DOMContentLoaded', () => {
  const body = document.body;
  const burgerMenu = document.getElementById('burger-menu')!;
  const overlay = document.getElementById('menu')!;

  const back = document.getElementById('menu-back')!;
  const root = document.getElementById('menu-root')!;

  const menuBottom = document.getElementById('menu__bottom')!;

  const menuContents = document.getElementsByClassName('mobile-menu-content');

  let menuOpen = false;

  function hideAllMenuContents() {
    for (const content of menuContents) {
      content.classList.add('hidden');
    }
  }

  burgerMenu.addEventListener('click', () => {
    burgerMenu.classList.toggle('close');
    overlay.classList.toggle('overlay');
    body.classList.toggle('no-scroll');
    back.classList.toggle('hidden');

    menuOpen = !menuOpen;

    if (menuOpen) {
      root.className = 'root';
      hideAllMenuContents();
    }
  });

  back.addEventListener('click', () => {
    root.classList.toggle('hidden');
    back.classList.toggle('hidden');
    menuBottom.classList.toggle('hidden');
    hideAllMenuContents();
  });

  for (const expandArrow of document.getElementsByClassName('menu-expand-arrow')) {
    expandArrow.addEventListener('click', () => {
      root.classList.toggle('hidden');
      back.classList.remove('hidden');
      menuBottom.classList.add('hidden');

      const id = expandArrow.id.replace('menu-expand-arrow-', '');
      document.getElementById(`mobile-menu-content-${id}`)?.classList.remove('hidden');
    });
  }
});
